import React from 'react';
import { Row, Col, Button } from 'antd';
import logo from 'assets/iconlogo-color.png';
import { Link } from 'react-router-dom';
import { HALF_SCREEN, FULL_SCREEN } from 'data/column-grid';
import { LinkedinFilled, FacebookFilled } from '@ant-design/icons';
import './_Footer.scss';

export const Footer: React.FC = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <Row id='extra-container'>
          <Col xs={HALF_SCREEN} sm={HALF_SCREEN}>
            <img src={logo} alt='logo' className='footer-logo' />
          </Col>
          <Col xs={HALF_SCREEN} sm={HALF_SCREEN}>
            <div className='extra-button-container' style={{ textAlign: 'right' }}>
              <Link to='https://www.facebook.com/VietnamTechSociety' target='_blank' rel='noopener noreferrer'>
                <Button icon={<FacebookFilled style={{ display: 'block', fontSize: '170%' }} />} />
              </Link>
              <Link
                to='https://www.linkedin.com/company/vietnam-tech-society/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button icon={<LinkedinFilled style={{ display: 'block', fontSize: '170%' }} />} />
              </Link>
            </div>
          </Col>
        </Row>
        <Row id='copyright'>
          <Col xs={FULL_SCREEN} sm={FULL_SCREEN} lg={HALF_SCREEN}>
            <p>Vietnam Tech Society © 2021 • Terms & Privacy • Limit Liability </p>
          </Col>
          <Col xs={FULL_SCREEN} sm={FULL_SCREEN} lg={HALF_SCREEN}>
            <div id='copyright-link' className='extra-button-container'>
              <Link to='https://vietnamtechsociety.org/about' target='_blank' rel='noopener noreferrer'>
                About Us
              </Link>
              <Link to='https://vietnamtechsociety.substack.com/' target='_blank' rel='noopener noreferrer'>
                Blog
              </Link>
              <Link to='privacy-policy' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </Link>
              <Link to='terms-of-service' target='_blank' rel='noopener noreferrer'>
                Term of Service
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
