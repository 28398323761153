export interface MentorReviewsStatistic {
  numberOfCall: number;
  numberOfFeedback: number;
  numberOfGivenFeedback: number;
}

export interface UserCustomizedUrlResponse {
  userId: string;
}

export interface UserProfileResponse {
  userProfile?: UserProfileInformation;
}

export interface UserProfileInformation {
  id?: string;
  email?: string;
  schedule?: string;
  allowPromotion?: boolean;
  signupDate?: number;
  // eslint-disable-next-line camelcase
  customized_url?: string;
  customizedUrl?: string;
  // Epoch time millisecs this user is suspended until
  suspended?: number;
  // Set availability of mentor
  availableDate?: number;
  name?: string;
  thumbnail?: string;
  location?: string;
  introduction?: string;
  hobby?: string;
  isStudent?: boolean;
  student?: boolean;
  need?: string[];
  offer?: string[];
  linkedin?: string;
  role?: string;
  organization?: string;
  expertise?: string;
  industry?: string;
  nylasAccessToken?: string;
  nylasSchedule?: string;
  mentorFAQ?: MentorFAQ[];
  buyMeACoffeeProfileURL?: string;
}

interface MentorFAQ {
  index: number;
  position: number;
  question: string;
  answer: string;
}

export interface MentorAvailabityRequest {
  mentorSize: number;
}

export interface MentorsAvailabilityRespond {
  totalMentors: number;
  mentorsAvailability: MentorAvailabity[];
}

export interface MentorAvailabity {
  mentorId?: string;
  mentorJob?: string;
  mentorPosition?: string;
  mentorFullName?: string;
  mentorCountry?: string;
  mentorIndustry?: string;
  mentorExpertise?: string;
  mentorOffers?: string[];
  mentorAvailable: boolean;
  mentorAvailability?: string[];
  mentorThumbnail?: string;
  mentorCustomizedUrl?: string;
}

export interface UpdateMentorNextAvailabilityRequest {
  availableDate: number;
}

export enum MentorApplicationStatus {
  RECEIVED = 'received',
  REJECTED = 'rejected',
}

export interface ApplyForMentorRequest {
  name?: string;
  email?: string;
  linkedin?: string;
  offer?: string;
  submittedTime?: number;
  why_TechSphere_answer: string;
  application_status?: string;
}
