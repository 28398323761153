import { useState, useEffect } from 'react';
import { auth } from 'utils/firebase';
import useAuthStore from 'store/useStateAuth';

export const useStateAuthUser = (): boolean => {
  const [isLoading, setLoading] = useState(true);
  const { logInAuthenticatedUser, isLoading: isAuthLoading } = useAuthStore((state) => state);

  useEffect(() => {
    const authListener = auth.onAuthStateChanged(async (user: firebase.default.User) => {
      setLoading(true);
      if (user?.uid) {
        await logInAuthenticatedUser(user);
      }
      setLoading(false);
    });

    return () => authListener(); // unsubscribe authentication listener
  }, []);

  return isLoading || isAuthLoading;
};
