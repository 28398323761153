/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dropdown, Menu, Divider, Button, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { QuestionCircleOutlined, BugOutlined } from '@ant-design/icons';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import TechSphereLogo from 'components/TechSphereLogo';
import { LoggingService, MONITOR_EVENT } from 'services/log/service';
import useAuthStore from 'store/useStateAuth';
import './_NavBar.scss';

const { Item, ItemGroup } = Menu;

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, authenticatedUser, logOutAuthenticatedUser, isLoading } = useAuthStore((state) => state);
  const { student: isStudent, id = '', thumbnail } = authenticatedUser;

  const handleLogout = async () => {
    const { success } = await logOutAuthenticatedUser();

    if (success) {
      navigate('/');
      return;
    }
  };

  const renderHamMenu = () => {
    if (isAuthenticated) {
      return (
        <>
          <Item
            key='setting:4'
            onClick={() => {
              LoggingService.monitorUserActivity(MONITOR_EVENT.VISIT_MY_PROFILE);
              navigate('/my-profile');
            }}
          >
            View Profile
          </Item>
          <Item
            key='setting:5'
            onClick={() => {
              LoggingService.monitorUserActivity(MONITOR_EVENT.MANAGE_ACCOUNT);
              navigate('/manage-account');
            }}
          >
            Manage account
          </Item>
          <Item
            key='setting:6'
            onClick={() => {
              LoggingService.monitorUserActivity(MONITOR_EVENT.REVIEW_PAGE);
              navigate('/reviews');
            }}
          >
            Reviews
          </Item>
        </>
      );
    }
    return (
      <>
        <Item
          key='setting:4'
          onClick={() => {
            LoggingService.monitorUserActivity(MONITOR_EVENT.SIGNUP);
            navigate('/signup', { state: { from: window.location.pathname } });
          }}
        >
          Sign up
        </Item>
        <Item
          key='setting:5'
          onClick={() => {
            LoggingService.monitorUserActivity(MONITOR_EVENT.LOGIN);
            navigate('/login', { state: { from: window.location.pathname } });
          }}
        >
          Log in
        </Item>
      </>
    );
  };

  const menu = (
    <Menu className='navbar-dropdown-menu'>
      <ItemGroup className='menu-item-group-wrapper'>
        {renderHamMenu()}
        <Divider style={{ margin: 'auto' }} />
      </ItemGroup>
      <ItemGroup className='menu-item-group-wrapper'>
        <Item key='setting:7'>
          <Link
            to='https://docs.google.com/forms/d/e/1FAIpQLSfrCRltttOX2IcUvIVh6kkoMLzBRxVgoa1ncyMm3xXjBOHs0A/viewform'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <BugOutlined className='icon-dropdown-menu' style={{ verticalAlign: '2px' }} />
            Report a bug
          </Link>
        </Item>
        <Item key='setting:9'>
          <Link
            to='https://www.notion.so/FAQ-2c612f335b724645a88be4b24357c5ee'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none' }}
          >
            <QuestionCircleOutlined className='icon-dropdown-menu' style={{ verticalAlign: '2px' }} />
            FAQ
          </Link>
        </Item>
      </ItemGroup>
      {isAuthenticated && (
        <>
          <ItemGroup className='menu-item-group-wrapper'>
            <Divider style={{ margin: 'auto' }} />
            <Item key='setting:10' onClick={() => handleLogout()}>
              Log Out
            </Item>
          </ItemGroup>
        </>
      )}
    </Menu>
  );

  return (
    <nav className='navbar'>
      <div className='navbar-content'>
        <TechSphereLogo />
        <div className='navbar-menu'>
          <div className='center-link'>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#7310DB' : '#858d95' })}
              onClick={() => LoggingService.monitorUserActivity(MONITOR_EVENT.HOMEPAGE)}
              to='/'
            >
              Home
            </NavLink>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#7310DB' : '#858d95' })}
              onClick={() => LoggingService.monitorUserActivity(MONITOR_EVENT.MENTOR_PAGE)}
              to='/mentors'
            >
              Mentors
            </NavLink>
          </div>
        </div>

        <div className='navbar-dropdown'>
          {(isStudent || id.length == 0) && (
            <Link
              className='navbar-extra-link-right'
              onClick={() => LoggingService.monitorUserActivity(MONITOR_EVENT.BECOME_A_MENTOR)}
              to='/mentor/setup/1/step'
            >
              Become a mentor
            </Link>
          )}
          <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
            <Button icon={<ThumbNailImage isLoading={isLoading} thumbnail={thumbnail} />}>
              <FontAwesomeIcon icon={faBars} className='menu-toggle-icon' />
            </Button>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

interface ThumbNailImageProps {
  isLoading: boolean;
  thumbnail: string;
}

const ThumbNailImage: React.FC<ThumbNailImageProps> = ({ isLoading, thumbnail }) => {
  return isLoading ? (
    <Skeleton.Avatar style={{ marginTop: '-18px' }} active={isLoading} size={'default'} shape={'circle'} />
  ) : thumbnail ? (
    <img className='navbar-icon-image' src={thumbnail} />
  ) : (
    <FontAwesomeIcon icon={faCircleUser} className='user-icon' />
  );
};
