import { create } from 'zustand';
import { logger } from './logger';
import { UserService } from 'services/user/service';
import { RequestRespond } from 'services/HTTPRequest';
import { UserProfileInformation } from 'services/user/model';
import { defaultProfilePic } from 'data/constants';
import { AuthenticationService } from 'services/auth/service';

interface AuthState {
  isLoading: boolean;
  isAuthenticated: boolean;
  authenticatedUser: UserProfileInformation;
}

export interface AuthStore extends AuthState {
  /*
    updateAuthUserProfile will update the user information from the database and if success, it will also
    update the authenticated user in zustand
    * @param {string} userId                          the user identification for auth user
    * @param {UserProfileInformation} updatedAuthUser the updated user profile information
    * @return {success}                               whether the update was successful or not
  */
  updateAuthUserProfile: (
    userId: string,
    updatedAuthUser: UserProfileInformation
  ) => Promise<RequestRespond<undefined>>;
  /*
    updateAuthUserNextAvailableTime will update the user available from the database and if success, it will also
    update the authenticated user available in zustand
    * @param {string} userId                          the user identification for auth user
    * @param {number} nextAvailDate                   the next available date
    * @return {success}                               whether the update was successful or not
  */
  updateAuthUserNextAvailableTime: (userId: string, nextAvailDate: number) => Promise<RequestRespond<undefined>>;
  /*
    logInAuthenticatedUser will get the latest user profile and update to the zustand itself
    * @return {success}       whether getting the latest user profile was successful or not
  */
  logInAuthenticatedUser: (user: firebase.default.User) => Promise<RequestRespond<undefined>>;
  /*
    logOutAuthenticatedUser will log out the current user and update to zustand the initiate state
    * @return {success}       whether log out the auth user profile was successful or not
  */
  logOutAuthenticatedUser: () => Promise<RequestRespond<undefined>>;
}

const initialAuthState: Pick<AuthStore, keyof AuthState> = {
  isLoading: false,
  isAuthenticated: false,
  authenticatedUser: {
    isStudent: true,
    thumbnail: defaultProfilePic,
  },
};

const useAuthStore = create<AuthStore>()(
  logger<AuthStore>(
    (set) => ({
      ...initialAuthState,
      /*
        updateAuthUserProfile will update the user information from the database and if success, it will also
        update the authenticated user in zustand
        * @param {string} userId                          the user identification for auth user
        * @param {UserProfileInformation} updatedAuthUser the updated user profile information
        * @return {success}                               whether the update was successful or not
      */
      updateAuthUserProfile: async (
        userId: string,
        updatedAuthUser: UserProfileInformation
      ): Promise<RequestRespond<undefined>> => {
        set((state) => ({ ...state, isLoading: true }));

        const { success } = await UserService.updateUserProfile(userId, updatedAuthUser);

        if (!success) {
          set((state) => ({ ...state, isLoading: false }));
          return { success: false };
        }

        set((authState) => ({
          ...authState.authenticatedUser,
          isLoading: false,
          authenticatedUser: { ...authState.authenticatedUser, ...updatedAuthUser },
        }));

        return { success: true };
      },
      /*
        updateAuthUserNextAvailableTime will update the user available from the database and if success, it will also
        update the authenticated user available in zustand
        * @param {string} userId                          the user identification for auth user
        * @param {number} nextAvailDate                   the next available date
        * @return {success}                               whether the update was successful or not
      */
      updateAuthUserNextAvailableTime: async (
        userId: string,
        nextAvailDate: number
      ): Promise<RequestRespond<undefined>> => {
        set((state) => ({ ...state, isLoading: true }));

        const { success } = await UserService.updateMentorNextAvailability(userId, nextAvailDate);

        if (!success) {
          set((state) => ({ ...state, isLoading: false }));
          return { success: false };
        }

        set((authState) => ({
          ...authState.authenticatedUser,
          isLoading: false,
          authenticatedUser: { ...authState.authenticatedUser, availableDate: nextAvailDate },
        }));

        return { success: true };
      },
      /*
        logInAuthenticatedUser will get the latest user profile and update to the zustand itself
        * @param {string} userId  the user identification for auth user
        * @return {success}       whether getting the latest user profile was successful or not
      */
      logInAuthenticatedUser: async (user: firebase.default.User): Promise<RequestRespond<undefined>> => {
        set((state) => ({ ...state, isLoading: true }));

        const { success } = await UserService.createUserAccountIfNotExist(user);
        if (!success) {
          set((state) => ({ ...state, isLoading: false }));
          return { success: false };
        }

        const authUserProfile = await UserService.getAuthUserInformation();
        if (authUserProfile == null || Object.keys(authUserProfile).length === 0) {
          set((state) => ({ ...state, isLoading: false }));
          return { success: false };
        }

        set(() => ({ isLoading: false, isAuthenticated: true, authenticatedUser: { ...authUserProfile } }));

        return { success: true };
      },
      /*
        logOutAuthenticatedUser will log out the current user and update to zustand the initiate state
        * @return {success}       whether log out the auth user profile was successful or not
      */
      logOutAuthenticatedUser: async (): Promise<RequestRespond<undefined>> => {
        set((state) => ({ ...state, isLoading: true }));

        const { success } = await AuthenticationService.logOutCurrentUser();
        if (!success) {
          set((state) => ({ ...state, isLoading: false }));
          return { success: false };
        }

        set(() => ({ ...initialAuthState }));
        return { success: true };
      },
    }),
    'authStore'
  )
);

export default useAuthStore;
