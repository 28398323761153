import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from 'layout/NavBar';
import Footer from 'layout/Footer';
import { Routers } from 'components/Routes';
import { LoadingCircle } from 'components/Loading';
import { useStateAuthUser } from 'features/auth/hooks/useStateAuthUser';
import { PagesWithoutNavbarAndFooter } from 'data/constants';
import './_App.scss';
import './lib/dayjs';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  const { pathname } = useLocation();
  const isLoading = useStateAuthUser();

  return (
    <React.Fragment>
      {PagesWithoutNavbarAndFooter.some((page) => page === pathname) || <NavBar />}
      {isLoading ? <LoadingCircle /> : <Routers />}
      {PagesWithoutNavbarAndFooter.some((page) => page === pathname) || <Footer />}
    </React.Fragment>
  );
}

export default App;
