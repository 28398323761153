import React from 'react';
import { Link } from 'react-router-dom';
import TechSphereLogo from 'components/TechSphereLogo';
import { Col, Button, Typography, Layout } from 'antd';
import Astronaut from 'assets/Images/astronaut.svg';
import '../LoginPage/_Login.scss';
import '../_pages.scss';

const { Paragraph, Title } = Typography;

export enum SimpleThankPageType {
  MENTOR_APPLICATION,
  USER_APPLICATION,
}
export const SimpleThankPage: React.FC<{ type: number }> = ({ type }) => {
  switch (type) {
    case SimpleThankPageType.MENTOR_APPLICATION:
      return (
        <Layout className='main-container-no-navbar page-container'>
          <Layout className='login-container'>
            <Layout className='login-wrapper'>
              <TechSphereLogo />
              <Col className='login-form-subtitle'>
                <Title level={3} style={{ marginTop: '5px' }}>
                  You&apos;re all set!
                </Title>
                <Paragraph style={{ marginBottom: '5px' }}>
                  TechSphere admin team is reviewing your application will get back to you within 3 days. In the mean
                  time, feel free to schedule coaching calls on TechSphere.
                </Paragraph>
              </Col>
              <img src={Astronaut} alt='Astronaut goes brrr' className='user-form-astrtonaut' />
            </Layout>
            <Link to='/' className='login-form'>
              <Button htmlType='submit' size='large' className='login-button' shape='round'>
                Homepage
              </Button>
            </Link>
          </Layout>
        </Layout>
      );
    case SimpleThankPageType.USER_APPLICATION:
      return (
        <Layout className='main-container-no-navbar page-container'>
          <Layout className='login-container'>
            <Layout className='login-wrapper'>
              <Col id='title'>
                <TechSphereLogo />
                <Col className='login-form-subtitle'>
                  <Title level={3} style={{ marginTop: '5px' }}>
                    You&apos;re all set!
                  </Title>
                  <Paragraph style={{ marginBottom: '5px' }}>
                    What you know about rollin&apos; down in the deep?
                    <br />
                    I feel like an astronaut in the ocean 🌊
                    <br />
                    An ocean of hope filled with mentors
                  </Paragraph>
                </Col>
                <img src={Astronaut} alt='Astronaut goes brrr' className='user-form-astrtonaut' />
              </Col>
            </Layout>
            <Link to='/' className='login-form'>
              <Button htmlType='submit' size='large' className='login-button' shape='round'>
                Homepage
              </Button>
            </Link>
          </Layout>
        </Layout>
      );
    default:
      return null;
  }
};
