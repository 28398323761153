import React, { SVGAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DNEImg: React.FC<SVGAttributes<HTMLOrSVGElement>> = (props) => (
  <svg width='508' height='401' viewBox='0 0 508 401' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_902_151)'>
      <path
        opacity='0.37'
        d='M64.1122 326.271H13.3723C-0.206983 312.149 -3.83337 289.637 4.27673 271.794C12.0025 254.79 28.607 243.549 38.6585 227.808C60.1212 194.213 49.7446 144.167 78.9035 117.008C93.478 103.428 114.438 99.5098 134.285 97.9209C154.131 96.332 174.638 96.3814 192.987 88.6539C215.612 79.1302 232.67 58.6716 255.986 50.9639C282.159 42.3087 313.466 53.1055 328.76 76.0708C338.328 90.4402 341.945 108.491 353.258 121.528C371.557 142.598 403.8 144.236 426.623 160.284C442.282 171.288 452.845 189.269 454.866 208.316C457.299 231.321 448.578 257.445 462.355 276.008C469.066 285.058 479.955 290.17 487.188 298.806C494.401 307.422 496.431 315.267 494.155 326.271H64.1122Z'
        fill='url(#paint0_linear_902_151)'
      />
      <path
        opacity='0.37'
        d='M77.0706 326.271H26.3405C12.7613 312.149 9.13489 289.637 17.245 271.794C24.9708 254.79 41.5753 243.549 51.6267 227.808C73.0894 194.213 62.7128 144.167 91.8717 117.008C106.446 103.428 127.406 99.5098 147.253 97.9209C167.1 96.332 187.606 96.3814 205.955 88.6539C228.581 79.1302 245.639 58.6716 268.954 50.9639C295.127 42.3087 326.434 53.1055 341.728 76.0708C351.297 90.4402 354.913 108.491 366.226 121.528C384.525 142.598 416.769 144.236 439.591 160.284C455.25 171.288 465.814 189.269 467.834 208.316C470.268 231.321 461.547 257.445 475.323 276.008C482.034 285.058 492.923 290.17 500.156 298.806C507.369 307.422 509.399 315.267 507.123 326.271H77.0706Z'
        fill='url(#paint1_linear_902_151)'
      />
      <path
        d='M193.834 152.29L191.745 152.359H3.07459L2.62129 152.418C0.74897 139.884 10.5442 127.153 22.8719 123.778C33.6525 120.827 46.2661 123.452 55.2926 116.85C63.383 110.938 65.3933 99.8849 70.6851 91.3679C73.9764 86.0682 78.6671 81.6468 84.1559 78.696C91.0342 74.9951 99.6173 73.7022 106.673 77.0479C119.168 82.989 121.563 99.5691 129.919 110.613C131.782 113.08 134.127 115.379 137.113 116.198C139.862 116.958 142.779 116.366 145.598 115.863C154.831 114.235 165.907 114.383 171.652 121.804C174.352 125.298 175.318 129.778 176.816 133.923C178.314 138.078 180.768 142.342 184.916 143.832C186.907 144.533 189.164 144.552 190.918 145.727C193.026 147.138 193.706 149.655 193.834 152.29Z'
        fill='url(#paint2_linear_902_151)'
      />
      <path
        d='M497.318 176.143H277.301C275.546 173.834 275.33 170.261 276.581 167.518C278.207 163.915 281.597 161.468 284.977 159.435C288.357 157.392 291.954 155.517 294.486 152.487C299.345 146.674 299.059 138.335 300.133 130.834C301.394 121.932 305.119 113.119 311.84 107.148C314.501 104.78 317.585 102.905 320.817 101.405C330.031 97.1116 341.442 96.0359 350.055 101.444C358.293 106.616 362.333 116.455 369.517 123.018C371.212 124.577 373.153 125.969 375.4 126.492C381.835 127.972 387.304 122.13 392.133 117.62C398.992 111.215 408.156 106.428 417.459 107.622C428.397 109.043 436.852 118.439 441 128.673C442.735 132.936 444.055 137.654 447.504 140.684C456.354 148.451 471.293 139.746 481.61 145.391C485.483 147.513 488.075 151.372 490.144 155.28C493.593 161.803 496.027 168.87 497.318 176.143Z'
        fill='url(#paint3_linear_902_151)'
      />
      <path
        d='M189.489 298.401H173.663V326.262H135.32V298.401H77.9969V269.85L137.773 182.321H173.653V269.356H189.479V298.401H189.489ZM135.32 269.347V252.609C135.32 249.46 135.438 245.207 135.664 239.858C135.891 234.509 136.068 231.706 136.206 231.44H135.122C132.895 236.492 130.373 241.22 127.554 245.621L111.63 269.347H135.32Z'
        fill='url(#paint4_linear_902_151)'
      />
      <path
        d='M179.014 298.401H163.187V326.262H124.844V298.401H67.5316V269.85L127.308 182.321H163.187V269.356H179.014V298.401ZM124.844 269.356V252.619C124.844 249.47 124.963 245.217 125.189 239.868C125.416 234.519 125.593 231.716 125.731 231.449H124.647C122.42 236.502 119.897 241.23 117.079 245.631L101.155 269.356H124.844Z'
        fill='url(#paint5_linear_902_151)'
      />
      <path
        d='M439.69 299.358L434.181 270.837L423.903 272.83L418.641 273.847L402.145 188.391L366.906 195.22L324.818 292.519L330.228 320.557L386.506 309.652L389.709 326.262H428.761L424.15 302.369L429.412 301.352L439.69 299.358ZM370.72 283.134L357.741 285.64L367.004 263.751L368.877 259.32C370.808 254.464 372.385 249.342 373.617 243.953L374.681 243.756C374.602 244.032 374.957 246.825 375.745 252.125C376.534 257.415 377.223 261.609 377.825 264.708L380.998 281.14L370.72 283.134Z'
        fill='url(#paint6_linear_902_151)'
      />
      <path
        d='M429.412 301.352L423.903 272.84L408.363 275.85L391.857 190.394L366.906 195.22L356.628 197.214L314.54 294.523L319.95 322.551L330.228 320.557L376.228 311.655L379.046 326.262H418.099L413.872 304.362L424.15 302.368L429.412 301.352ZM367.004 263.751C367.192 264.827 367.379 265.804 367.546 266.702L370.72 283.134L347.453 287.644L358.599 261.313C360.53 256.467 362.107 251.345 363.339 245.957L364.403 245.75C364.324 246.036 364.679 248.829 365.467 254.119C366.029 257.889 366.541 261.106 367.004 263.751Z'
        fill='url(#paint7_linear_902_151)'
      />
      <path
        d='M194.84 253.25H171.436L156.565 144.207C156.565 144.207 135.083 120.699 110.418 116.297L133.91 113.761L186.858 115.202L194.84 253.25Z'
        fill='url(#paint8_linear_902_151)'
      />
      <path
        d='M102.455 193.305C102.455 193.305 89.152 212.807 95.0548 229.545L119.799 193.305L116.586 188.983L102.455 193.305Z'
        fill='#111023'
      />
      <path
        d='M171.436 253.25L170.637 258.283C170.637 258.283 163.582 259.27 163.188 269.356H181.526L191.489 259.616L192.415 253.25H171.436Z'
        fill='#111023'
      />
      <path
        d='M143.114 144.128L113.778 143.032L118.547 188.026L98.6614 195.161L87.2009 139.598C83.8504 129.245 90.8568 118.419 101.657 117.245L110.398 116.297H110.408C135.073 120.699 156.556 144.207 156.556 144.207L143.114 144.128Z'
        fill='url(#paint9_linear_902_151)'
      />
      <path
        d='M89.0929 18.5539V19.8665L87.5064 20.0738C87.1023 19.1954 86.6589 18.4947 86.2253 18.1098C84.1066 16.2742 82.6285 14.8333 81.1109 15.7116C79.9678 16.3827 80.0565 19.5211 80.1649 21.0015L75.8389 21.5541L37.5055 26.4196L37.4858 26.3505C39.9691 25.7583 41.9006 21.2975 41.9006 15.8695C41.9006 10.5106 40.0283 6.09916 37.5942 5.40833L89.1028 11.2311V12.2673V18.5539H89.0929Z'
        fill='url(#paint10_linear_902_151)'
      />
      <path
        d='M40.3534 8.2112C40.245 8.3691 40.1465 8.52701 40.0578 8.70465C39.506 9.77051 39.2498 10.9943 38.5698 11.9713C38.0771 12.7016 37.3479 13.2642 36.5398 13.58C35.6825 13.9155 34.7365 13.9747 33.8102 14.0932C33.1992 14.1721 32.5685 14.3004 32.0068 14.557C32.3025 9.43496 34.3127 5.44786 36.796 5.30969L37.5942 5.39851C38.6486 5.70445 39.6144 6.72097 40.3534 8.2112Z'
        fill='url(#paint11_linear_902_151)'
      />
      <path
        d='M41.8808 15.0011C41.8611 15.0208 41.8513 15.0405 41.8316 15.0504C41.2009 15.8991 40.8954 16.9551 40.314 17.8236C39.634 18.85 38.5402 19.5902 37.338 19.8665C36.2244 20.1132 35.0321 19.9849 34.0171 20.4883C33.5046 20.7547 33.0809 21.1692 32.7754 21.6528C32.263 19.9849 31.9575 17.9914 31.9575 15.8498C31.9575 15.4057 31.9674 14.9715 32.0068 14.5372C32.5685 14.2806 33.1992 14.1523 33.8101 14.0734C34.7364 13.9451 35.6726 13.8957 36.5398 13.5602C37.3577 13.2444 38.0771 12.6818 38.5698 11.9515C39.2497 10.9745 39.5158 9.75072 40.0578 8.68486C40.1465 8.51709 40.245 8.35918 40.3534 8.19141C41.2107 9.95797 41.7823 12.3265 41.8808 15.0011Z'
        fill='url(#paint12_linear_902_151)'
      />
      <path
        d='M41.9005 15.8597C41.9005 21.2877 39.969 25.7584 37.4858 26.3407C37.3084 26.39 37.1113 26.4098 36.9241 26.4098C35.1897 26.4098 33.6623 24.5248 32.7754 21.6627C33.0907 21.1693 33.5046 20.7647 34.017 20.4982C35.032 19.9949 36.2343 20.1232 37.3379 19.8764C38.5402 19.6001 39.634 18.8501 40.314 17.8335C40.9052 16.9651 41.2008 15.8992 41.8315 15.0603C41.8512 15.0406 41.8611 15.0209 41.8808 15.011C41.8906 15.2873 41.9005 15.5735 41.9005 15.8597Z'
        fill='url(#paint13_linear_902_151)'
      />
      <path
        d='M119.444 13.2838H134.935C133.319 13.9352 131.939 14.6754 131.319 15.2576C130.826 15.7116 131.043 16.5406 131.644 17.5966H119.434V13.2838H119.444Z'
        fill='#111023'
      />
      <path
        d='M153.787 15.8794V17.5966H145.391C144.583 15.7313 143.981 14.1523 143.775 13.2838H153.787V15.8794Z'
        fill='#111023'
      />
      <path d='M119.444 17.5966V18.5539H89.0929V12.2574H119.444V13.2838V17.5966Z' fill='url(#paint14_linear_902_151)' />
      <path
        d='M90.2951 29.3014L83.6139 34.3938C83.6139 34.3938 75.5038 25.8768 75.829 21.5541L80.155 21.0015C80.0466 19.5113 79.9579 16.3729 81.1011 15.7117C82.6186 14.8333 84.0968 16.2742 86.2154 18.1098C86.649 18.4947 87.0826 19.1954 87.4965 20.0738C89.0633 23.4194 90.2951 29.3014 90.2951 29.3014Z'
        fill='url(#paint15_linear_902_151)'
      />
      <path
        d='M45.3298 5.43794C44.8863 4.4609 43.4377 5.29977 43.8812 6.26694C46.6305 12.2871 46.2758 18.9092 42.9844 24.6431C42.4523 25.5708 43.891 26.4097 44.4232 25.482C48.0299 19.2152 48.3255 12.0009 45.3298 5.43794Z'
        fill='#5B2B89'
      />
      <path d='M164.331 36.0222V41.1837L158.162 35.3412L164.331 36.0222Z' fill='url(#paint16_linear_902_151)' />
      <path
        d='M164.331 41.1837L170.539 47.0558L176.461 52.6614C176.461 52.6614 190.04 43.1378 195.303 43.0588C195.677 43.049 196.032 43.0786 196.377 43.1279C201.028 43.7891 203.866 49.1678 203.048 54.3293C202.171 59.8955 202.329 66.0439 196.978 67.0407C191.627 68.0473 168.065 73.7714 168.065 73.7714L142.277 38.7263L145.312 36.6045L152.082 31.8574L153.451 30.9001L156.427 33.7128L158.152 35.3412L164.331 41.1837Z'
        fill='url(#paint17_linear_902_151)'
      />
      <path
        d='M180.984 37.0979L196.387 43.1279C196.042 43.0786 195.687 43.0489 195.312 43.0588C190.04 43.1378 176.471 52.6614 176.471 52.6614L170.549 47.0558L180.984 37.0979Z'
        fill='url(#paint18_linear_902_151)'
      />
      <path
        d='M196.988 67.0504L188.04 91.4468L186.838 115.202C186.838 115.202 185.793 115.389 183.97 115.646C175.436 116.869 149.874 119.781 133.891 113.761L140.118 84.2424L137.241 60.9612L102.505 64.8102L80.7068 36.6044L83.6138 34.3838L90.295 29.2914L110.9 49.444L143.144 35.3313C144.316 36.18 145.331 36.5945 145.331 36.5945L142.296 38.7163L168.085 73.7614C168.075 73.7713 191.647 68.0472 196.988 67.0504Z'
        fill='url(#paint19_linear_902_151)'
      />
      <path
        d='M152.091 31.8673L145.322 36.6143C145.322 36.6143 144.307 36.1998 143.134 35.351C141.606 34.2556 139.803 32.4495 139.576 29.9033C139.281 26.4886 133.526 20.9225 131.644 17.6065C131.043 16.5505 130.826 15.7215 131.319 15.2675C131.939 14.6753 133.319 13.945 134.935 13.2937C138.512 11.8725 143.292 10.8955 143.725 13.116C143.735 13.1654 143.755 13.2345 143.774 13.2937C143.981 14.1621 144.582 15.7412 145.391 17.6065C147.844 23.3305 152.091 31.8673 152.091 31.8673Z'
        fill='url(#paint20_linear_902_151)'
      />
      <path
        opacity='0.38'
        d='M188.04 91.4468C148.573 83.0581 142.286 38.7361 142.286 38.7361L168.085 73.8009L196.998 67.0504L188.04 91.4468Z'
        fill='url(#paint21_linear_902_151)'
      />
      <path
        d='M180.758 15.5536C181.595 15.3267 182.433 15.4944 182.778 16.8268C183.556 19.8763 180.137 23.5476 180.137 23.5476L180.984 37.0978L170.549 47.0557L174.638 35.3411L164.34 36.0221L158.172 35.3411L156.447 33.7127C156.447 33.7127 155.245 22.4719 155.245 15.8793C155.245 14.3595 155.304 13.0864 155.472 12.2574L155.521 11.9416C162.734 13.1653 172.746 10.3428 172.746 10.3428C172.746 10.3428 174.145 24.0312 176.067 24.268C177.989 24.495 178.58 16.8268 178.58 16.8268C178.58 16.8268 179.674 15.8497 180.758 15.5536Z'
        fill='url(#paint22_linear_902_151)'
      />
      <path
        d='M180.837 5.60567L180.758 15.5537C179.674 15.8498 178.58 16.8268 178.58 16.8268C178.58 16.8268 177.989 24.4951 176.067 24.2681C174.145 24.0312 172.746 10.3428 172.746 10.3428C172.746 10.3428 162.734 13.1654 155.521 11.9416V11.9317C153.254 11.5567 151.274 10.7771 149.963 9.38553C144.445 3.56277 146.366 0.266506 154.269 2.06268C162.172 3.85885 178.56 -5.67467 180.837 5.60567Z'
        fill='#111023'
      />
      <path d='M174.628 35.3412L170.539 47.0558L164.331 41.1837V36.0222L174.628 35.3412Z' fill='#F79244' />
      <path
        d='M154.831 13.7476C156.417 12.9482 158.073 12.8594 159.738 13.4614C160.753 13.8266 159.985 15.3365 158.98 14.9714C157.925 14.5964 156.743 14.6654 155.747 15.1688C154.782 15.6425 153.855 14.2312 154.831 13.7476Z'
        fill='#111023'
      />
      <path
        d='M170.242 19.5405C170.91 19.5056 171.41 18.6741 171.358 17.6835C171.307 16.6929 170.723 15.9182 170.054 15.9532C169.386 15.9882 168.886 16.8196 168.938 17.8103C168.989 18.8009 169.573 19.5755 170.242 19.5405Z'
        fill='#111023'
      />
      <path
        d='M172.263 13.343C170.598 12.7114 168.952 12.7903 167.346 13.57C166.37 14.0437 167.287 15.455 168.253 14.9911C169.258 14.5075 170.45 14.4582 171.495 14.8529C172.51 15.228 173.278 13.7279 172.263 13.343Z'
        fill='#111023'
      />
      <path
        d='M160.576 23.3108C161.118 20.7645 162.429 18.169 163.315 15.7116C163.503 15.1984 164.252 15.5833 164.064 16.0866C163.503 17.636 162.941 19.1756 162.379 20.7251C162.182 21.258 161.709 22.1462 161.66 22.7088C161.473 24.6332 162.36 24.4852 163.355 24.949C163.848 25.176 163.463 25.9359 162.98 25.6991C161.759 25.1365 160.202 25.0675 160.576 23.3108Z'
        fill='#F79244'
      />
      <path
        d='M159.65 17.9617C158.861 17.3301 157.965 16.9057 156.959 16.7775C156.339 16.6985 156.082 17.6558 156.713 17.7347C157.226 17.8038 157.699 17.942 158.152 18.1591C157.63 18.3269 157.097 18.5045 156.575 18.6723C155.974 18.8697 156.23 19.827 156.831 19.6296C157.699 19.3434 158.566 19.0671 159.433 18.7809C159.738 18.6822 159.955 18.2085 159.65 17.9617Z'
        fill='#111023'
      />
      <path
        d='M163.72 34.0088C164.928 34.0088 165.907 32.5904 165.907 30.8408C165.907 29.0912 164.928 27.6729 163.72 27.6729C162.511 27.6729 161.532 29.0912 161.532 30.8408C161.532 32.5904 162.511 34.0088 163.72 34.0088Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M465.764 326.271H70.35C70.35 326.271 49.0745 340.414 80.7069 342.812C112.339 345.21 161.67 347.47 160.458 358.247C159.246 369.024 130.678 366.893 130.678 385.92C130.678 404.948 262.43 400.556 262.43 400.556C262.43 400.556 336.86 401.533 347.463 388.93C358.067 376.337 317.457 364.8 324.828 359.836C332.199 354.872 385.698 369.774 393.67 357.704C401.642 345.644 392.074 338.006 406.776 336.417C421.479 334.828 463.281 340.601 465.764 326.271Z'
        fill='url(#paint23_linear_902_151)'
      />
      <path
        d='M435.866 359.846C452.379 359.846 465.764 356.033 465.764 351.329C465.764 346.625 452.379 342.812 435.866 342.812C419.354 342.812 405.968 346.625 405.968 351.329C405.968 356.033 419.354 359.846 435.866 359.846Z'
        fill='url(#paint24_linear_902_151)'
      />
      <path
        d='M117.562 365.165C131.995 365.165 143.696 362.267 143.696 358.691C143.696 355.116 131.995 352.217 117.562 352.217C103.129 352.217 91.4283 355.116 91.4283 358.691C91.4283 362.267 103.129 365.165 117.562 365.165Z'
        fill='url(#paint25_linear_902_151)'
      />
      <path
        d='M267.348 389.651C283.74 389.651 297.029 387.088 297.029 383.927C297.029 380.765 283.74 378.203 267.348 378.203C250.955 378.203 237.667 380.765 237.667 383.927C237.667 387.088 250.955 389.651 267.348 389.651Z'
        fill='#E9FDFF'
      />
      <path
        d='M306.597 250.112C300.507 236.167 289.086 227.482 272.334 224.058C270.511 223.683 268.727 223.386 266.983 223.169C252.201 221.294 240.012 224.827 230.414 233.769C219.672 243.756 211.749 261.294 206.625 286.381C203.492 301.747 202.615 315.04 204.004 326.262H240.623C240.159 319.709 241.45 308.991 244.486 294.128C247.767 278.051 251.019 267.195 254.241 261.55C256.114 258.274 258.232 256.112 260.597 255.066C262.302 254.306 264.145 254.129 266.106 254.533C270.797 255.5 273.497 259.32 274.206 265.991C274.916 272.673 273.674 283.825 270.491 299.447C268.096 311.162 265.692 320.093 263.288 326.262H303.434C305.297 320.488 306.932 314.133 308.342 307.195C309.278 302.605 310.017 298.204 310.549 293.99C312.845 276.028 311.524 261.402 306.597 250.112Z'
        fill='url(#paint26_linear_902_151)'
      />
      <path
        d='M296.339 248.02C290.87 235.496 281.085 227.206 266.983 223.169C265.396 222.715 263.761 222.311 262.066 221.965C244.86 218.442 230.887 221.679 220.145 231.676C209.414 241.674 201.481 259.211 196.367 284.288C195.322 289.391 194.534 294.276 193.992 298.924C192.8 309.139 192.81 318.258 194.031 326.262H230.601C230.552 326.025 230.522 325.788 230.492 325.541C229.724 319.156 230.965 307.984 234.217 292.036C237.499 275.959 240.751 265.103 243.983 259.468C247.205 253.823 251.157 251.484 255.848 252.451C257.769 252.846 259.356 253.714 260.597 255.066C262.42 257.02 263.524 259.961 263.948 263.899C264.657 270.58 263.416 281.732 260.233 297.355C257.552 310.461 254.862 320.093 252.162 326.262H292.496C294.624 319.945 296.487 312.899 298.083 305.112C303.001 280.992 302.419 261.965 296.339 248.02Z'
        fill='url(#paint27_linear_902_151)'
      />
      <path
        d='M324.296 153.967L324.049 154.155L298.3 174.229L282.228 186.752L268.845 223.84L265.13 222.498L278.917 184.334L321.881 150.849L324.296 153.967Z'
        fill='#2A2A2A'
      />
      <path
        d='M336.633 237.203C333.707 240.687 329.716 243.075 325.803 245.394L325.537 244.931C327.085 242.947 328.72 240.618 328.257 238.141C327.932 236.453 326.69 235.111 325.419 233.976C323.261 232.071 320.857 230.463 318.797 228.459C316.738 226.456 314.993 223.939 314.658 221.087C314.225 217.337 316.245 213.794 317.033 210.103C317.348 208.622 317.457 207.043 316.866 205.652C315.545 202.513 311.554 201.793 308.391 200.53C305.563 199.385 302.902 197.263 302.114 194.312C300.971 190.029 304.016 185.647 303.602 181.236C303.316 178.156 301.128 175.403 298.3 174.229L324.049 154.155C328.415 157.945 332.179 162.968 332.229 168.702C332.248 172.334 330.78 175.788 329.735 179.262C329.321 180.663 328.967 182.124 329.223 183.555C329.627 185.746 331.371 187.404 332.977 188.953C336.298 192.141 339.639 196.019 339.58 200.628C339.521 205.8 335.116 210.409 335.884 215.521C336.377 218.787 338.89 221.393 339.895 224.531C341.275 228.874 339.56 233.719 336.633 237.203Z'
        fill='url(#paint28_linear_902_151)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_902_151'
        x1='245.188'
        y1='340.096'
        x2='261.179'
        y2='98.9833'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_902_151'
        x1='258.153'
        y1='340.096'
        x2='274.145'
        y2='98.9833'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_902_151'
        x1='98.116'
        y1='153.08'
        x2='98.116'
        y2='111.031'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9E6D9' />
        <stop offset='0.121' stopColor='#FCF1EA' />
        <stop offset='0.2698' stopColor='#FEF9F6' />
        <stop offset='0.4751' stopColor='#FFFEFD' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_902_151'
        x1='385.964'
        y1='182.597'
        x2='388.629'
        y2='141.614'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9E6D9' />
        <stop offset='0.121' stopColor='#FCF1EA' />
        <stop offset='0.2698' stopColor='#FEF9F6' />
        <stop offset='0.4751' stopColor='#FFFEFD' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_902_151'
        x1='78.0015'
        y1='254.287'
        x2='189.487'
        y2='254.287'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_902_151'
        x1='124.289'
        y1='335.741'
        x2='123.223'
        y2='281.45'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9E6D9' />
        <stop offset='0.121' stopColor='#FCF1EA' />
        <stop offset='0.2698' stopColor='#FEF9F6' />
        <stop offset='0.4751' stopColor='#FFFEFD' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_902_151'
        x1='321.533'
        y1='275.514'
        x2='431.005'
        y2='254.371'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_902_151'
        x1='372.621'
        y1='330.865'
        x2='371.554'
        y2='276.574'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9E6D9' />
        <stop offset='0.121' stopColor='#FCF1EA' />
        <stop offset='0.2698' stopColor='#FEF9F6' />
        <stop offset='0.4751' stopColor='#FFFEFD' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_902_151'
        x1='148.95'
        y1='185.728'
        x2='180.49'
        y2='177.589'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_902_151'
        x1='86.3578'
        y1='155.732'
        x2='156.559'
        y2='155.732'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_902_151'
        x1='37.4806'
        y1='15.9083'
        x2='89.0929'
        y2='15.9083'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#51ADC6' />
        <stop offset='0.0060817' stopColor='#55AFC4' />
        <stop offset='0.0663' stopColor='#74BFB5' />
        <stop offset='0.1333' stopColor='#8FCDA7' />
        <stop offset='0.2102' stopColor='#A5D89D' />
        <stop offset='0.3001' stopColor='#B6E094' />
        <stop offset='0.4112' stopColor='#C1E78E' />
        <stop offset='0.5672' stopColor='#C8EA8B' />
        <stop offset='1' stopColor='#CAEB8A' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_902_151'
        x1='40.3504'
        y1='9.93325'
        x2='31.9986'
        y2='9.93325'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8AEBD6' />
        <stop offset='0.4615' stopColor='#89E9D7' />
        <stop offset='0.6276' stopColor='#84E2DA' />
        <stop offset='0.7461' stopColor='#7DD7DE' />
        <stop offset='0.8419' stopColor='#72C6E5' />
        <stop offset='0.9229' stopColor='#64B0EE' />
        <stop offset='0.9944' stopColor='#5395F9' />
        <stop offset='1' stopColor='#5193FA' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_902_151'
        x1='41.8786'
        y1='14.9384'
        x2='31.9541'
        y2='14.9384'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_902_151'
        x1='41.8963'
        y1='20.709'
        x2='32.7714'
        y2='20.709'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8AEBD6' />
        <stop offset='0.4615' stopColor='#89E9D7' />
        <stop offset='0.6276' stopColor='#84E2DA' />
        <stop offset='0.7461' stopColor='#7DD7DE' />
        <stop offset='0.8419' stopColor='#72C6E5' />
        <stop offset='0.9229' stopColor='#64B0EE' />
        <stop offset='0.9944' stopColor='#5395F9' />
        <stop offset='1' stopColor='#5193FA' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_902_151'
        x1='89.0929'
        y1='15.4056'
        x2='119.444'
        y2='15.4056'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_902_151'
        x1='75.818'
        y1='24.914'
        x2='90.2924'
        y2='24.914'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F7BE86' />
        <stop offset='0.3293' stopColor='#F7BC83' />
        <stop offset='0.5448' stopColor='#F7B67B' />
        <stop offset='0.7276' stopColor='#F7AC6C' />
        <stop offset='0.8912' stopColor='#F79E57' />
        <stop offset='1' stopColor='#F79244' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_902_151'
        x1='161.309'
        y1='35.9939'
        x2='160.829'
        y2='53.4303'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#7B6ED6' />
        <stop offset='0.4804' stopColor='#796DD5' />
        <stop offset='0.6535' stopColor='#7268D0' />
        <stop offset='0.7768' stopColor='#6760C9' />
        <stop offset='0.8765' stopColor='#5655BE' />
        <stop offset='0.961' stopColor='#4047B0' />
        <stop offset='1' stopColor='#333EA8' />
      </linearGradient>
      <linearGradient
        id='paint17_linear_902_151'
        x1='173.043'
        y1='36.2234'
        x2='171.761'
        y2='76.6951'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#7B6ED6' />
        <stop offset='0.4804' stopColor='#796DD5' />
        <stop offset='0.6535' stopColor='#7268D0' />
        <stop offset='0.7768' stopColor='#6760C9' />
        <stop offset='0.8765' stopColor='#5655BE' />
        <stop offset='0.961' stopColor='#4047B0' />
        <stop offset='1' stopColor='#333EA8' />
      </linearGradient>
      <linearGradient
        id='paint18_linear_902_151'
        x1='183.697'
        y1='36.6111'
        x2='183.216'
        y2='54.0474'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#7B6ED6' />
        <stop offset='0.4804' stopColor='#796DD5' />
        <stop offset='0.6535' stopColor='#7268D0' />
        <stop offset='0.7768' stopColor='#6760C9' />
        <stop offset='0.8765' stopColor='#5655BE' />
        <stop offset='0.961' stopColor='#4047B0' />
        <stop offset='1' stopColor='#333EA8' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_902_151'
        x1='80.7055'
        y1='73.3672'
        x2='196.991'
        y2='73.3672'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#7B6ED6' />
        <stop offset='0.4804' stopColor='#796DD5' />
        <stop offset='0.6535' stopColor='#7268D0' />
        <stop offset='0.7768' stopColor='#6760C9' />
        <stop offset='0.8765' stopColor='#5655BE' />
        <stop offset='0.961' stopColor='#4047B0' />
        <stop offset='1' stopColor='#333EA8' />
      </linearGradient>
      <linearGradient
        id='paint20_linear_902_151'
        x1='131.053'
        y1='24.2012'
        x2='152.096'
        y2='24.2012'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F7BE86' />
        <stop offset='0.3293' stopColor='#F7BC83' />
        <stop offset='0.5448' stopColor='#F7B67B' />
        <stop offset='0.7276' stopColor='#F7AC6C' />
        <stop offset='0.8912' stopColor='#F79E57' />
        <stop offset='1' stopColor='#F79244' />
      </linearGradient>
      <linearGradient
        id='paint21_linear_902_151'
        x1='142.287'
        y1='65.0886'
        x2='196.991'
        y2='65.0886'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3C0887' />
        <stop offset='0.3227' stopColor='#3F0987' />
        <stop offset='0.5338' stopColor='#470E87' />
        <stop offset='0.7129' stopColor='#561588' />
        <stop offset='0.8732' stopColor='#6B1F89' />
        <stop offset='1' stopColor='#822A8A' />
      </linearGradient>
      <linearGradient
        id='paint22_linear_902_151'
        x1='155.241'
        y1='28.6949'
        x2='182.891'
        y2='28.6949'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F7BE86' />
        <stop offset='0.3293' stopColor='#F7BC83' />
        <stop offset='0.5448' stopColor='#F7B67B' />
        <stop offset='0.7276' stopColor='#F7AC6C' />
        <stop offset='0.8912' stopColor='#F79E57' />
        <stop offset='1' stopColor='#F79244' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_902_151'
        x1='263.502'
        y1='403.678'
        x2='264.568'
        y2='330.226'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8AEBD6' />
        <stop offset='0.4615' stopColor='#89E9D7' />
        <stop offset='0.6276' stopColor='#84E2DA' />
        <stop offset='0.7461' stopColor='#7DD7DE' />
        <stop offset='0.8419' stopColor='#72C6E5' />
        <stop offset='0.9229' stopColor='#64B0EE' />
        <stop offset='0.9944' stopColor='#5395F9' />
        <stop offset='1' stopColor='#5193FA' />
      </linearGradient>
      <linearGradient
        id='paint24_linear_902_151'
        x1='435.868'
        y1='398.964'
        x2='435.868'
        y2='334.294'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8AEBD6' />
        <stop offset='0.4615' stopColor='#89E9D7' />
        <stop offset='0.6276' stopColor='#84E2DA' />
        <stop offset='0.7461' stopColor='#7DD7DE' />
        <stop offset='0.8419' stopColor='#72C6E5' />
        <stop offset='0.9229' stopColor='#64B0EE' />
        <stop offset='0.9944' stopColor='#5395F9' />
        <stop offset='1' stopColor='#5193FA' />
      </linearGradient>
      <linearGradient
        id='paint25_linear_902_151'
        x1='117.563'
        y1='398.964'
        x2='117.563'
        y2='334.294'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8AEBD6' />
        <stop offset='0.4615' stopColor='#89E9D7' />
        <stop offset='0.6276' stopColor='#84E2DA' />
        <stop offset='0.7461' stopColor='#7DD7DE' />
        <stop offset='0.8419' stopColor='#72C6E5' />
        <stop offset='0.9229' stopColor='#64B0EE' />
        <stop offset='0.9944' stopColor='#5395F9' />
        <stop offset='1' stopColor='#5193FA' />
      </linearGradient>
      <linearGradient
        id='paint26_linear_902_151'
        x1='210.09'
        y1='269.393'
        x2='311.812'
        y2='290.149'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#31D1D6' />
        <stop offset='0.3733' stopColor='#32CED8' />
        <stop offset='0.6175' stopColor='#36C6DF' />
        <stop offset='0.824' stopColor='#3CB7EB' />
        <stop offset='1' stopColor='#45A3FA' />
      </linearGradient>
      <linearGradient
        id='paint27_linear_902_151'
        x1='248.003'
        y1='333.312'
        x2='246.937'
        y2='279.021'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9E6D9' />
        <stop offset='0.121' stopColor='#FCF1EA' />
        <stop offset='0.2698' stopColor='#FEF9F6' />
        <stop offset='0.4751' stopColor='#FFFEFD' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint28_linear_902_151'
        x1='298.296'
        y1='199.779'
        x2='340.376'
        y2='199.779'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#7B6ED6' />
        <stop offset='0.4804' stopColor='#796DD5' />
        <stop offset='0.6535' stopColor='#7268D0' />
        <stop offset='0.7768' stopColor='#6760C9' />
        <stop offset='0.8765' stopColor='#5655BE' />
        <stop offset='0.961' stopColor='#4047B0' />
        <stop offset='1' stopColor='#333EA8' />
      </linearGradient>
      <clipPath id='clip0_902_151'>
        <rect width='508' height='401' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
