import React from 'react';
import { LoggingService, MONITOR_EVENT } from 'services/log/service';
import Logo from 'assets/Images/techsphere-logo.svg';
import './_TechSphereLogo.scss';

const TechSphereLogo: React.FC = () => {
  return (
    <div className='techsphere-logo-container'>
      <a href='/' onClick={() => LoggingService.monitorUserActivity(MONITOR_EVENT.HOMEPAGE)}>
        <img className='techsphere-logo' src={Logo} alt='TechSphere logo' />
      </a>
    </div>
  );
};

export default TechSphereLogo;
