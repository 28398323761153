import { HTTPRequest, DataRespond, RequestRespond } from '../HTTPRequest';
import {
  UserCustomizedUrlResponse,
  UserProfileInformation,
  UserProfileResponse,
  MentorReviewsStatistic,
  MentorAvailabityRequest,
  MentorsAvailabilityRespond,
  MentorApplicationStatus,
  ApplyForMentorRequest,
} from './model';
import { collection, doc, getDoc, getDocs, query, updateDoc, setDoc, where } from 'firebase/firestore';
import { DUPLICATE_CUSTOMIZED_URL, LOG_OUT_ERROR, UPDATE_CUSTOMIZED_URL_FAILED } from 'data/errors';
import { defaultProfilePic } from 'data/constants';

interface UserInterface {
  /**
   * getUserIdViaCustomizedUrl will get the user id via user customized url
   * @return {String} return the user id
   */
  getUserIdViaCustomizedUrl(customizedUrl?: string): Promise<string>;

  /**
   * getUserInformation will get the information for a given user and return a user profile
   * @return {UserProfileInformation} return ser profile's information
   */
  getUserInformation(userId?: string): Promise<UserProfileInformation | undefined>;

  /**
   * getMentorReviewStatistics will return the mentor statistic review (e.g number of calls, number of writing reviews,
   * number of received reviews)
   * @return {MentorReviewsStatistic}  return the mentor statistic review
   */
  getMentorReviewStatistics(userId?: string): Promise<MentorReviewsStatistic>;

  /**
   * getMentorsAvailability will return all the mentors availabiltiy that sorting from their availability dates
   * @param {number} mentorSize  the expected mentor size needs to be returned
   * @return {MentorsAvailabilityRespond}  all the mentors availabiltiy and information
   */
  getMentorsAvailability(mentorSize?: number): Promise<MentorsAvailabilityRespond>;

  /**
   * updateMentorNextAvailability will update the next availability date for the mentors; however,
   * it will not cancel all the mentors previous scheduled calls
   * @param {number} nextAvailability  next Availability date for the customer in unix time zone
   */
  updateMentorNextAvailability(userId?: string, nextAvailability?: number): Promise<RequestRespond<undefined>>;

  /**
   * updateUserProfile will update the user information with their provided information
   * @param {string} userId the user identification that needs to updated
   * @param {UserProfileInformation} updateUserProfile the updated user profile information
   */
  updateUserProfile(userId?: string, updateUserProfile?: UserProfileInformation): Promise<RequestRespond<undefined>>;

  /**
   * applyForMentor will create a request for admin to accept/reject
   * @param {string} userId the user identification that needs to create a request to be a mentor
   * @param {ApplyForMentorRequest} applyForMentorRequest the mentor request payload for admin user to review
   */
  applyForMentor(userId?: string, applyForMentorRequest?: ApplyForMentorRequest): Promise<RequestRespond<undefined>>;

  /**
   * updateMentorCustomizedUrl will update the mentor customized url (for better sharing link)
   * @param {string}        userId        the user identification that needs to create a request to be a mentor
   * @param {string}        customizedUrl the mentor's customer url'
   * @return {success}                    whether the request was successful or not
   */
  updateMentorCustomizedUrl(userId?: string, customizedUrl?: string): Promise<RequestRespond<undefined>>;

  /**
   * getAuthUserInformation will get the information for a current user and return a user profile
   * @return {UserProfileInformation} return  auth user profile's information
   */
  getAuthUserInformation(): Promise<UserProfileInformation | undefined>;

  /**
   * isUserAnAdmin will check if the current user is an admin or not
   * @param {string}                userEmail the user email
   * @return {success}              whether the user is an admin or not
   */
  isUserAnAdmin(userEmail?: string): Promise<RequestRespond<undefined>>;

  /**
   * Create user account in the firestore and store all user information if its not exist
   * @return {success}              whether the request was successful or not
   */

  createUserAccountIfNotExist(user: firebase.default.User): Promise<RequestRespond<undefined>>;
}

class UService extends HTTPRequest implements UserInterface {
  // All the endpoints or API call that relates to user service
  private static readonly USER_SERVICE: string = '/user-service';
  private static readonly USER_CUSTOMIZED_URL: string = UService.USER_SERVICE + '/customized-url/';
  private static readonly MENTOR_STATISTIC: string = UService.USER_SERVICE + '/mentor-call-statistic/';
  private static readonly MY_PROFILE: string = UService.USER_SERVICE + '/my-profile';
  private static readonly MENTOR_PROFILE: string = UService.USER_SERVICE + '/mentor-profile/';
  private static readonly MENTORS_AVAILABILITY: string = UService.USER_SERVICE + '/mentor-availability';

  /**
   * getUserIdViaCustomizedUrl will get the user id via user customized url
   * @return {String} return the user id
   */
  public async getUserIdViaCustomizedUrl(customizedUrl: string = ''): Promise<string> {
    try {
      const { success, data } = await this.get<DataRespond & UserCustomizedUrlResponse>(
        UService.USER_CUSTOMIZED_URL + customizedUrl
      );

      if (!success || data?.responseCode != 200) {
        return '';
      }

      return data.userId;
    } catch (error: unknown) {
      return '';
    }
  }

  /**
   * getUserInformation will get the information for a given user and return a user profile
   * @param {string} userId the mentor identification that is used to get mentor/mentee's profile
   * @return {UserProfileInformation} return user profile's information
   */
  public async getUserInformation(userId?: string): Promise<UserProfileInformation | undefined> {
    try {
      const { success, data } = await this.get<DataRespond & UserProfileResponse>(UService.MENTOR_PROFILE + userId);

      if (!success || data?.responseCode !== 200) {
        return {};
      }

      return data.userProfile;
    } catch (error: unknown) {
      return {};
    }
  }

  /**
   * getAuthUserInformation will get the information for a current user and return a user profile
   * @return {UserProfileInformation} return  auth user profile's information
   */
  public async getAuthUserInformation(): Promise<UserProfileInformation | undefined> {
    try {
      const { success, data } = await this.get<DataRespond & UserProfileResponse>(UService.MY_PROFILE);

      if (!success || data?.responseCode !== 200) {
        return {};
      }

      return data.userProfile;
    } catch (error: unknown) {
      return {};
    }
  }

  /**
   * getMentorReviewStatistics will return the mentor statistic review (e.g number of calls, number of writing reviews,
   * number of received reviews)
   * @param {string}                  userId            the mentor identification that is used to get mentor/mentee's statistics
   * @return {MentorReviewsStatistic} mentorStatistics  return the mentor statistic review
   */
  public async getMentorReviewStatistics(userId: string = ''): Promise<MentorReviewsStatistic> {
    try {
      const { success, data } = await this.get<DataRespond & MentorReviewsStatistic>(
        UService.MENTOR_STATISTIC + userId
      );
      if (!success || data?.responseCode != 200) {
        return {
          numberOfCall: 0,
          numberOfFeedback: 0,
          numberOfGivenFeedback: 0,
        };
      }

      return {
        numberOfCall: data?.numberOfCall,
        numberOfFeedback: data?.numberOfFeedback,
        numberOfGivenFeedback: data?.numberOfGivenFeedback,
      };
    } catch (error: unknown) {
      return {
        numberOfCall: 0,
        numberOfFeedback: 0,
        numberOfGivenFeedback: 0,
      };
    }
  }

  /**
   * getMentorsAvailability will return all the mentors availabiltiy that sorting from their availability dates
   * @param {number}            mentorSize          the expected mentor size needs to be returned
   * @return {MentorFeedback[]} mentorsAvailability return all the mentors availabiltiy and information
   */
  public async getMentorsAvailability(mentorSize: number = 4): Promise<MentorsAvailabilityRespond> {
    try {
      const { success, data } = await this.post<DataRespond & MentorsAvailabilityRespond, MentorAvailabityRequest>(
        UService.MENTORS_AVAILABILITY,
        {
          mentorSize: mentorSize,
        }
      );

      if (!success || data?.responseCode != 200 || data.mentorsAvailability == null || data.totalMentors == null) {
        return {
          totalMentors: 0,
          mentorsAvailability: [],
        };
      }

      return {
        totalMentors: data.totalMentors,
        mentorsAvailability: data.mentorsAvailability,
      };
    } catch (error: unknown) {
      return {
        totalMentors: 0,
        mentorsAvailability: [],
      };
    }
  }

  /**
   * updateMentorNextAvailability will update the next availability date for the mentors; however,
   * it will not cancel all the mentors previous scheduled calls
   * @param {string} userId            the user identification that needs to updated
   * @param {number} nextAvailability  next Availability date for the customer in unix time zone
   * @return {success}                 whether update the user availabity is successful or not
   */
  public async updateMentorNextAvailability(
    userId: string = '',
    nextAvailability: number = 0
  ): Promise<RequestRespond<undefined>> {
    try {
      const userReference = await doc(collection(this.firestoreDatabase, 'users'), userId);

      await updateDoc(userReference, { availableDate: nextAvailability });

      return { success: true };
    } catch (error: unknown) {
      return { success: false };
    }
  }

  /**
   * updateUserProfile will update the user information with their provided information
   * @param {string}                 userId             the user identification that needs to updated
   * @param {UserProfileInformation} updateUserProfile  the updated user profile information
   * @return {success}                                  whether the update was successful or not
   */
  public async updateUserProfile(
    userId: string = '',
    updateUserProfile: UserProfileInformation
  ): Promise<RequestRespond<undefined>> {
    try {
      const userReference = await doc(collection(this.firestoreDatabase, 'users'), userId);

      await updateDoc(userReference, { ...updateUserProfile });

      return { success: true };
    } catch (error: unknown) {
      return { success: false };
    }
  }

  /**
   * updateMentorCustomizedUrl will update the mentor customized url (for better sharing link)
   * @param {string}        userId        the user identification that needs to create a request to be a mentor
   * @param {string}        customizedUrl the mentor's customer url'
   * @return {success}                    whether the request was successful or not
   */
  public async updateMentorCustomizedUrl(userId?: string, customizedUrl?: string): Promise<RequestRespond<undefined>> {
    try {
      const userDocuments = await getDocs(
        query(collection(this.firestoreDatabase, 'users'), where('customized_url', '==', customizedUrl))
      );

      if (!userDocuments.empty) {
        return { success: false, error: DUPLICATE_CUSTOMIZED_URL };
      }

      await this.updateUserProfile(userId, {
        customized_url: customizedUrl,
      });

      return { success: true };
    } catch (error: unknown) {
      return { success: false, error: UPDATE_CUSTOMIZED_URL_FAILED };
    }
  }

  /**
   * applyForMentor will create a request for admin to accept/reject
   * @param {string}                userId the user identification that needs to create a request to be a mentor
   * @param {ApplyForMentorRequest} applyForMentorRequest the mentor request payload for admin user to review
   * @return {success}              whether the request was successful or not
   */
  public async applyForMentor(
    userId?: string,
    applyForMentorRequest?: ApplyForMentorRequest
  ): Promise<RequestRespond<undefined>> {
    try {
      const appReference = await doc(collection(this.firestoreDatabase, 'mentor_application'), userId);
      const appDocument = await getDoc(appReference);

      if (appDocument.exists() && appDocument.data().application_status !== MentorApplicationStatus.REJECTED) {
        return { success: true };
      }

      await setDoc(appReference, {
        ...applyForMentorRequest,
        application_status: MentorApplicationStatus.RECEIVED,
        submittedTime: Date.now(),
      });

      return { success: true };
    } catch (error: unknown) {
      return { success: false };
    }
  }

  /**
   * isUserAnAdmin will check if the current user is an admin or not
   * @param {string}                userEmail the user email
   * @return {success}              whether the user is an admin or not
   */
  public async isUserAnAdmin(userEmail?: string): Promise<RequestRespond<undefined>> {
    try {
      const userReference = await doc(collection(this.firestoreDatabase, 'admins'), userEmail);
      const userDocument = await getDoc(userReference);

      if (userDocument.exists()) {
        return { success: true };
      }

      return { success: false };
    } catch (error: unknown) {
      return { success: false };
    }
  }

  /**
   * Create user account in the firestore and store all user information if its not exist
   * @return {success}              whether the request was successful or not
   */

  public async createUserAccountIfNotExist(user: firebase.default.User): Promise<RequestRespond<undefined>> {
    try {
      const userReference = await doc(collection(this.firestoreDatabase, 'users'), user.uid);
      const userDocument = await getDoc(userReference);
      if (userDocument.exists()) {
        return { success: true };
      }

      await setDoc(
        userReference,
        {
          id: user.uid,
          email: user.email,
          name: user.displayName ?? '',
          thumbnail: defaultProfilePic,
          customized_url: user.uid,
          linkedin: '',
          organization: '',
          role: '',
          need: [],
          offer: [],
          schedule: '',
          introduction: '',
          hobby: '',
          expertise: '',
          industry: '',
          location: '',
          allowPromotion: false,
          suspended: 0,
          availableDate: 0,
          signupDate: new Date(user.metadata.creationTime).getTime(),
          isStudent: true,
        },
        { merge: true }
      );

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_OUT_ERROR,
      };
    }
  }
}

export const UserService = new UService();
