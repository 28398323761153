import { LOG_IN_ERROR, LOG_OUT_ERROR, RESET_PASSWORD_ERROR, SEND_RESET_PASSWORD_ERROR } from 'data/errors';
import { HTTPRequest, DataRespond } from '../HTTPRequest';
import { RequestRespond } from '../HTTPRequest';
import {
  UserCredential,
  EmailAuthProvider,
  EmailAuthCredential,
  GoogleAuthProvider,
  GithubAuthProvider,
} from 'firebase/auth';
import {
  applyActionCode,
  updatePassword,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendEmailVerification,
  signOut,
} from 'firebase/auth';

interface AuthInterface {
  /**
   * logInWithEmailAndPassword will authenticate the user for the current window
   * via email and password
   * @param {string} email             the user's email for authentication
   * @param {string} password          the user's password for authentication
   * @return {SecurityPolicyViolationEventDisposition, error}          return if the authentication process is success or not
   */
  logInWithEmailAndPassword(email: string, password: string): Promise<RequestRespond<undefined>>;

  /**
   * getUserEmail will return the user email
   * @return {string}   return the user email
   */
  getUserEmail(): string;

  /**
   * createAccountWithEmailAndPassword will register a new user via email and password
   * @param {string} email             the user's email for registration
   * @param {string} password          the user's password for registration
   * @return {Success, error}          return if the authentication process is success or not
   */
  createAccountWithEmailAndPassword(email: string, password: string): Promise<RequestRespond<undefined>>;

  /**
   * loginWithGithub will authenticate the user for the current window via github
   * @return {Success, error}          return if the authentication process is success or not
   */
  loginWithGithub(): Promise<RequestRespond<undefined>>;

  /**
   * logInWithGoogle will authenticate the user for the current window via google
   * @return {Success, error}          return if the authentication process is success or not
   */
  logInWithGoogle(): Promise<RequestRespond<undefined>>;

  /**
   * logOutCurrentUser will log out the current use with firebase session; however, in the future, we will maintain an access
   * token alongside with cookies instead of using firebase
   * https://stackoverflow.com/a/61106253
   * @return {SecurityPolicyViolationEventDisposition, error}          return if the authentication process is success or not
   */
  logOutCurrentUser(): Promise<RequestRespond<undefined>>;

  /**
   * sendPasswordResetEmail will send a reset password email for the user
   * @return {Success, error}          return if the authentication process is success or not
   */
  sendPasswordResetEmail(email: string): Promise<RequestRespond<undefined>>;

  /**
   * sendEmailVerificationToUser will send email verification for the user
   * @return {Success, error}    return if the send email process is success or not
   */
  sendEmailVerificationToUser(): Promise<RequestRespond<undefined>>;

  /**
   * updatePassword will reset the password for a user
   * @param {string} currentPassword   the user's current password
   * @param {string} newPassword       the user's new password
   * @return {Success, error}          return if the authentication process is success or not
   */
  updatePassword(currentPassword: string, newPassword: string): Promise<RequestRespond<undefined>>;

  /**
   * resetPassword will send a reset password email for the user
   * @param {string} actionCode        the user's code for reseting the password
   * @param {string} password          the user's reset password
   * @return {Success, error}          return if the authentication process is success or not
   */
  resetPassword(actionCode: string, password: string): Promise<RequestRespond<undefined>>;

  /**
   * isUserEmailVerified will return if the user is email verified or not
   * @return {boolean}          return if the user is email verified or not
   */
  isUserEmailVerified(): Promise<boolean>;

  /**
   * verifyEmailWithActionCode will validate the email with user action's code
   * @param {string} actionCode   the user's code for verifying the email
   * @return {Success, error}     return if the email is validated
   */
  verifyEmailWithActionCode(actionCode: string): Promise<RequestRespond<undefined>>;
}

class AuthService extends HTTPRequest implements AuthInterface {
  private static readonly AUTH_SERVICE: string = '/auth-service';
  private static readonly LOG_IN_USER: string = AuthService.AUTH_SERVICE + '/auth-session-cookie';
  private static readonly LOG_OUT_USER: string = AuthService.AUTH_SERVICE + '/log-out';

  private readonly googleAuthProvider: GoogleAuthProvider;
  private readonly githubAuthProvider: GithubAuthProvider;

  public constructor() {
    super();
    this.githubAuthProvider = new GithubAuthProvider();
    this.googleAuthProvider = new GoogleAuthProvider();
  }

  /**
   * logInWithEmailAndPassword will authenticate the user for the current window
   * via email and password
   * @param {string} email             the user's email for authentication
   * @param {string} password          the user's password for authentication
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async logInWithEmailAndPassword(email: string, password: string): Promise<RequestRespond<undefined>> {
    try {
      const { user }: UserCredential = await signInWithEmailAndPassword(this.firebaseAuthentication, email, password);

      // Get session token from the server
      const token = (await user?.getIdToken()) ?? '';

      const { success, data } = await this.get<DataRespond>(AuthService.LOG_IN_USER, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Signout current user to use the session token for upcoming API calls
      // https://firebase.google.com/docs/auth/admin/manage-cookies
      // However, since we are still using firestore for others call to database,
      // we don't log out
      // await signOut(this.firebaseAuthentication);
      if (!success || data?.responseCode != 200) {
        return { success: false, error: LOG_IN_ERROR };
      }

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_IN_ERROR,
      };
    }
  }

  /**
   * createAccountWithEmailAndPassword will register a new user via email and password
   * @param {string} email             the user's email for registration
   * @param {string} password          the user's password for registration
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async createAccountWithEmailAndPassword(email: string, password: string): Promise<RequestRespond<undefined>> {
    try {
      // If the new account was created, the user is signed in automatically.
      // https://firebase.google.com/docs/auth/web/password-auth#create_a_password-based_account
      const { user }: UserCredential = await createUserWithEmailAndPassword(
        this.firebaseAuthentication,
        email,
        password
      );

      const { success: emailSuccess, error } = await this.sendEmailVerificationToUser();
      if (!emailSuccess) {
        return { success: false, error: error };
      }

      // Get session token from the server
      const token = (await user?.getIdToken()) ?? '';

      const { success: logInSuccess, data } = await this.get<DataRespond>(AuthService.LOG_IN_USER, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!logInSuccess || data?.responseCode != 200) {
        return { success: false, error: LOG_IN_ERROR };
      }

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_IN_ERROR,
      };
    }
  }

  /**
   * getUserEmail will return the user email
   * @return {string}   return the user email
   */
  public getUserEmail(): string {
    try {
      if (this.firebaseAuthentication?.currentUser?.email == null) {
        return '';
      }

      return this.firebaseAuthentication.currentUser.email;
    } catch (error: unknown) {
      return '';
    }
  }

  /**
   * isUserEmailVerified will return if the user is email verified or not
   * @return {boolean}          return if the user is email verified or not
   */
  public async isUserEmailVerified(): Promise<boolean> {
    try {
      if (this.firebaseAuthentication?.currentUser == null) {
        return false;
      }

      await this.firebaseAuthentication.currentUser.reload();
      return this.firebaseAuthentication.currentUser.emailVerified;
    } catch (error: unknown) {
      return false;
    }
  }

  /**
   * verifyEmailWithActionCode will validate the email with user action's code
   * @param {string} actionCode   the user's code for verifying the email
   * @return {Success, error}     return if the email is validated
   */
  public async verifyEmailWithActionCode(actionCode: string = ''): Promise<RequestRespond<undefined>> {
    try {
      await applyActionCode(this.firebaseAuthentication, actionCode);

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : RESET_PASSWORD_ERROR,
      };
    }
  }

  /**
   * updatePassword will update the password for a user
   * @param {string} currentPassword   the user's current password
   * @param {string} newPassword       the user's new password
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async updatePassword(
    currentPassword: string = '',
    newPassword: string = ''
  ): Promise<RequestRespond<undefined>> {
    try {
      if (this.firebaseAuthentication?.currentUser == null || this.firebaseAuthentication.currentUser.email == null) {
        return { success: false, error: LOG_IN_ERROR };
      }

      const emailAuthCredential: EmailAuthCredential = EmailAuthProvider.credential(
        this.firebaseAuthentication.currentUser.email,
        currentPassword
      );

      await reauthenticateWithCredential(this.firebaseAuthentication.currentUser, emailAuthCredential);

      await updatePassword(this.firebaseAuthentication.currentUser, newPassword);

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : RESET_PASSWORD_ERROR,
      };
    }
  }

  /**
   * resetPassword will reset the password for a user and log in with that password
   * @param {string} actionCode        the user's code for reseting the password
   * @param {string} password          the user's reset password
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async resetPassword(actionCode: string = '', password: string = ''): Promise<RequestRespond<undefined>> {
    try {
      const email = await verifyPasswordResetCode(this.firebaseAuthentication, actionCode);

      await confirmPasswordReset(this.firebaseAuthentication, actionCode, password);

      await this.logInWithEmailAndPassword(email, password);
      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : RESET_PASSWORD_ERROR,
      };
    }
  }

  /**
   * sendEmailVerificationToUser will send email verification for the user
   * @return {Success, error}    return if the send email process is success or not
   */
  public async sendEmailVerificationToUser(): Promise<RequestRespond<undefined>> {
    try {
      if (this.firebaseAuthentication?.currentUser == null) {
        return { success: false, error: LOG_IN_ERROR };
      }

      if (await this.isUserEmailVerified()) {
        return { success: true };
      }

      await sendEmailVerification(this.firebaseAuthentication.currentUser);

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : RESET_PASSWORD_ERROR,
      };
    }
  }

  /**
   * sendPasswordResetEmail will send a reset password email for the user
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async sendPasswordResetEmail(email: string = ''): Promise<RequestRespond<undefined>> {
    try {
      await sendPasswordResetEmail(this.firebaseAuthentication, email);

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : SEND_RESET_PASSWORD_ERROR,
      };
    }
  }

  /**
   * loginWithGithub will authenticate the user for the current window via github
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async loginWithGithub(): Promise<RequestRespond<undefined>> {
    try {
      const { user }: UserCredential = await signInWithPopup(this.firebaseAuthentication, this.githubAuthProvider);
      const token = (await user?.getIdToken()) ?? '';
      const { success, data } = await this.get<DataRespond>(AuthService.LOG_IN_USER, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!success || data?.responseCode != 200) {
        return { success: false, error: LOG_IN_ERROR };
      }

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_IN_ERROR,
      };
    }
  }

  /**
   * logInWithGoogle will authenticate the user for the current window via google
   * @return {Success, error}          return if the authentication process is success or not
   */
  public async logInWithGoogle(): Promise<RequestRespond<undefined>> {
    try {
      const { user }: UserCredential = await signInWithPopup(this.firebaseAuthentication, this.googleAuthProvider);

      const token = (await user?.getIdToken()) ?? '';

      const { success, data } = await this.get<DataRespond>(AuthService.LOG_IN_USER, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!success || data?.responseCode != 200) {
        return { success: false, error: LOG_IN_ERROR };
      }

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_IN_ERROR,
      };
    }
  }

  /**
   * logOutCurrentUser will log out the current use with firebase session; however, in the future, we will maintain an access
   * token alongside with cookies instead of using firebase
   * https://stackoverflow.com/a/61106253
   * @return {SecurityPolicyViolationEventDisposition, error}          return if the authentication process is success or not
   */
  public async logOutCurrentUser(): Promise<RequestRespond<undefined>> {
    try {
      const { success, data } = await this.get<DataRespond>(AuthService.LOG_OUT_USER);

      if (!success || data?.responseCode != 200) {
        return { success: false };
      }
      // TODO: Remove the signout when all migrations away from firebase are done
      await signOut(this.firebaseAuthentication);

      return { success: true };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : LOG_OUT_ERROR,
      };
    }
  }
}

export const AuthenticationService = new AuthService();
