// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT

import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { VTSButton } from 'components/Button';
import { DNEImg } from 'assets/Images/dne';
import { FULL_SCREEN } from 'data/column-grid';
import TechSphereLogo from 'components/TechSphereLogo';
import './_ErrorBoundary.scss';

const { Text, Title } = Typography;
/**
 * An error screen that will be used as a fallback UI.
 * @see https://reactjs.org/docs/error-boundaries
 */
export class ErrorBoundary extends React.Component<Props> {
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  override state: State = { error: undefined };

  override render() {
    const { error } = this.state;
    if (!error) {
      return this.props.children;
    }

    return (
      <Layout className='main-container-no-navbar page-outer-container'>
        <Layout className='page-container'>
          <Col span={FULL_SCREEN}>
            <Row className='logo-row'>
              <TechSphereLogo />
            </Row>
            <Row>
              <Title level={3} className='page-title'>
                Page encouters an error
              </Title>
            </Row>
            <Row className='page-subtitle-row'>
              <Title level={4}>Error {error.status || 500}: </Title>
              <Text style={{ marginTop: '-15px', fontSize: '18px' }}>{error.message}</Text>
            </Row>
            <Row className='page-subtitle-row'>
              <Col span={24}>
                <DNEImg />
              </Col>
            </Row>
            <Row align={'middle'} justify={'center'}>
              <a href='/'>
                <VTSButton label='Go back to homepage' colorType='primary' />
              </a>
            </Row>
          </Col>
        </Layout>
      </Layout>
    );
  }
}

type Props = {
  children: React.ReactNode;
};

type State = {
  error: (Error & { status?: number }) | undefined;
};
