import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import firebase from 'firebase/compat/app';
import { Auth } from 'firebase/auth';
import { getAuth, browserLocalPersistence } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';

interface HTTPRequestInterface {
  /**
   * post HTTP call which post all the required informations to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */

  post<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>>;

  /**
   * get HTTP call which gets all the data from the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */

  get<T>(url: string, config?: AxiosRequestConfig): Promise<RequestRespond<T>>;

  /**
   * patch HTTP call which updates a small set of changes for a particular data to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */
  patch<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>>;

  /**
   * put HTTP call which updates all the data to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */
  put<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>>;
}

type FirebaseConfiguration = { [key: string]: string };

// Data Response is the respond inside the axios request that coming from the server
export interface DataRespond {
  responseCode?: number;
  statusMessage?: string;
}

// RequestRespond is the respond for the axios request
export interface RequestRespond<ResponseData> {
  success: boolean;
  data?: ResponseData;
  error?: string;
}

export class HTTPRequest implements HTTPRequestInterface {
  private readonly FirebaseConfiguration: FirebaseConfiguration = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  private readonly AxiosRetryConfiguration = {
    maximumRetry: 2,
  };

  private readonly AxiosConfiguration: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_VTS_USER_SERVICE_URI,
    timeout: 60000,
    headers: {},
    withCredentials: true,
    responseType: 'json',
    maxRedirects: 3,
  };

  public axiosInstance: AxiosInstance;
  protected firebaseInstance: firebase.app.App;

  protected firestoreDatabase: Firestore;
  protected firebaseAuthentication: Auth;

  public constructor() {
    // Setting up the required configuration with interceptors for axios
    this.axiosInstance = this.configureAxiosInstance();
    // Setting up the required configuration for firebase and related authentication mechanism
    this.firebaseInstance = firebase.initializeApp(this.FirebaseConfiguration);
    // Authentication for firebase
    this.firebaseAuthentication = getAuth(this.firebaseInstance);
    // Apply the default browser preference instead of explicitly setting it.
    this.firebaseAuthentication.useDeviceLanguage();
    // Firestore with firebase (temp, we will change to api server later on)
    this.firestoreDatabase = getFirestore(this.firebaseInstance);
    // There are 3 modes: indefinite, current window (session) and not persist
    // To avoid security problems, using session. However, in the future,
    // we can have a keep login to changing session to indefinite (e.g Meta)
    // https://jsmobiledev.com/article/firebase-auth-persistence/
    // Once finish convert firestore to API call, turns to none
    // https://firebase.google.com/docs/auth/admin/manage-cookies
    this.firebaseAuthentication.setPersistence(browserLocalPersistence);
  }

  /**
   * post HTTP call which post all the required informations to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */

  public async post<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>> {
    try {
      const { data, status } = await this.axiosInstance.post<T>(url, body, config);

      if (status != 200) {
        return { success: false };
      }

      return { success: true, data: data };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : '',
      };
    }
  }

  /**
   * get HTTP call which gets all the data from the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<RequestRespond<T>> {
    try {
      const { data, status } = await this.axiosInstance.get<T>(url, config);

      if (status != 200) {
        return { success: false };
      }

      return { success: true, data: data };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : '',
      };
    }
  }

  /**
   * patch HTTP call which updates a small set of changes for a particular data to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */
  public async patch<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>> {
    try {
      const { data, status } = await this.axiosInstance.patch<T>(url, body, config);

      if (status != 200) {
        return { success: false };
      }

      return { success: true, data: data };
    } catch (error: unknown) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : '',
      };
    }
  }

  /**
   * put HTTP call which updates all the data to the subsequent endpoint
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   */
  public async put<T, B>(url: string, body?: B, config?: AxiosRequestConfig): Promise<RequestRespond<T>> {
    try {
      const { data, status } = await this.axiosInstance.put<T>(url, body, config);

      if (status != 200) {
        return { success: false };
      }

      return { success: true, data: data };
    } catch (error) {
      return {
        success: false,
        error: typeof error === 'string' ? error : error instanceof Error ? error.message : '',
      };
    }
  }

  private configureAxiosInstance(): AxiosInstance {
    const axiosInstance = axios.create(this.AxiosConfiguration);

    axiosRetry(axiosInstance, {
      retries: this.AxiosRetryConfiguration.maximumRetry,
      retryDelay: (retryCount) => {
        return retryCount * 100;
      },
      retryCondition: (error: AxiosError): boolean => {
        const { response } = error;
        const { status } = response;
        const config = error.config as InternalAxiosRequestConfig;
        if (config['axios-retry'].retryCount === this.AxiosRetryConfiguration.maximumRetry) {
          // When the user is returned with 401 (Unauthorized), return the use to log in page
          // (e.g when the cookie session is out of time - 5 days or the user does not have the permission
          // to call the API)
          window.location.assign('/login');
        }
        return status === 401 || status === 403;
      },
    });
    return axiosInstance;
  }
}

export const HTTPService = new HTTPRequest();
