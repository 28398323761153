/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './_Button.scss';
import { CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';

interface VTSButtonProps {
  /**
   * button's action type
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Is this the principal call to action on the page?
   */
  colorType: 'primary' | 'secondary' | 'link' | 'accent' | 'grey' | 'warning' | 'alert' | 'success' | 'info';
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * external-styling for component
   */
  className?: string;
  /**
   * inline-styling for component
   */
  style?: React.CSSProperties;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (values?: any) => void;
  /**
   * Is disable
   */
  disabled?: boolean;
  /**
   * Is icon available and check which side, it's on
   */
  reverse?: boolean;
  pathname?: string;
  isLink?: boolean;
}

/**
 * Primary UI component for user interaction
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const VTSButton = ({
  size = 'medium',
  label,
  reverse = null,
  colorType = 'primary',
  type = 'button',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disabled = false,
  className = '',
  style = {},
  onClick,
  pathname,
  isLink,
  ...props
}: VTSButtonProps) => {
  if (reverse !== null) {
    return (
      <button
        type={type}
        className={[
          'storybook-button',
          `storybook-button--${size}`,
          `${`storybook-button--${colorType}`}`,
          className,
        ].join(' ')}
        onClick={onClick}
        disabled={disabled}
        style={style}
        {...props}
      >
        {reverse ? '' : <CheckOutlined className='iconLeft' />}
        <span>{label}</span>
        {reverse ? <ArrowRightOutlined className='iconRight' /> : ''}
      </button>
    );
  }
  if (isLink) {
    return (
      <a className={`${`storybook-button--${colorType}`}`} style={style} href={pathname} onClick={onClick}>
        <span>{label}</span>
      </a>
    );
  }
  return (
    <button
      type={type}
      className={['storybook-button', `storybook-button--${size}`, `${`storybook-button--${colorType}`}`].join(' ')}
      style={style}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <span>{label}</span>
    </button>
  );
};
