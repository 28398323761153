// this column's width = full screen
export const FULL_SCREEN: number = 24;
// this column's width = half of screen
export const HALF_SCREEN: number = 12;
// this column's width = 1/3 of screen
export const THIRD_SCREEN: number = 8;
// this column's width = 1/4 of screen
export const QUARTER_SCREEN: number = 6;
// this column's width = 1/8 of screen
export const ONE_EIGHTH_SCREEN: number = 3;
// this column's width = 1/6 of screen
export const ONE_SIXTH_SCREEN: number = 4;
// this column's width = 1/12 of screen
export const ONE_TWELVE_SCREEN: number = 2;
// this column's width = 2/3 of screen
export const TWO_THIRD_SCREEN: number = 16;
