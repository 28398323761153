import React, { Suspense, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LoadingCircle } from 'components/Loading';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <Suspense fallback={<LoadingCircle />}>
          <Router>
            <App />
          </Router>
        </Suspense>
      </HelmetProvider>
    </ErrorBoundary>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
