import React, { lazy } from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { SimpleThankPageType } from 'pages/SimpleThankPage/SimpleThankPage';
import useAuthStore from 'store/useStateAuth';
import '../pages/_pages.scss';

export const Routers: React.FC = () => (
  <Routes>
    <Route path='/' element={<LandingPage />} />

    {/*
      Firebase SMS message management
      https://support.google.com/firebase/answer/7000714
    */}
    <Route path='/action' element={<ActionPage />} />

    {/* Mentee/Mentor account actions */}
    <Route path='/login' element={<LoginPage />} />
    <Route path='/signup' element={<SignUpPage />} />
    <Route path='/forgot-password' element={<ForgotPasswordPage />} />
    <Route path='/reset-password' element={<ResetPasswordPage />} />

    {/* Mentor actions */}
    <Route path='/mentors' element={<Mentors />} />
    <Route path='/mentors/:mentorParam' element={<UserProfilePage />} />
    <Route path='/profile/:profileParam' element={<UserProfilePage />} />

    {/* Required Authentication before further related mentor/mentee actions */}
    <Route element={<PrivateRoutes />}>
      <Route path='/my-profile' element={<UserProfilePage />} />
      <Route path='/mentee/setup/:stepId/step' element={<MenteeSetupPage />} />
      <Route path='/mentee/setup/finish' element={<SimpleThankPage type={SimpleThankPageType.USER_APPLICATION} />} />
      <Route path='/mentor/setup/:stepId/step' element={<MentorFormPage />} />
      <Route path='/mentor/setup/finish' element={<SimpleThankPage type={SimpleThankPageType.MENTOR_APPLICATION} />} />

      <Route path='/verify-email' element={<VerifyEmailPage />} />
      <Route path='/manage-account' element={<ManageAccountPage />} />
      <Route path='/admins' element={<AdminPage />} />
      <Route path='/reviews' element={<ReviewPage />} />

      <Route path='/booking-confirmation' element={<FinishBookingPage />} />
    </Route>

    {/* Related company's policy */}
    <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
    <Route path='/terms-of-service' element={<TermsOfServicePage />} />

    {/* Page not found*/}
    <Route path='*' element={<DNEPage />} />
  </Routes>
);
/*
  Instead of loading all the pages/components when opening the SPA, loading when the customers
  render that page
  -  https://developer.mozilla.org/en-US/docs/Web/Performance/Lazy_loading
  -  https://blog.bitsrc.io/lazy-loading-react-components-with-react-lazy-and-suspense-f05c4cfde10c
*/

// eslint-disable-next-line no-use-before-define
const ActionPage = lazy(() => import('../pages/ActionPage'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const SignUpPage = lazy(() => import('../pages/SignUpPage'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const Mentors = lazy(() => import('../pages/MentorsPage'));
const UserProfilePage = lazy(() => import('../pages/UserProfilePage'));
const SimpleThankPage = lazy(() => import('../pages/SimpleThankPage'));
const VerifyEmailPage = lazy(() => import('../pages/VerifyEmailPage'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage'));
const ManageAccountPage = lazy(() => import('../pages/ManageAccountPage'));
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('../pages/TermsOfServicePage'));
const AdminPage = lazy(() => import('../pages/AdminPage'));
const ReviewPage = lazy(() => import('../pages/ReviewPage'));
const DNEPage = lazy(() => import('../pages/DNEPage'));
const FinishBookingPage = lazy(() => import('../pages/FinishBookingPage'));
const MenteeSetupPage = lazy(() => import('../pages/MenteeSetupPage'));
const MentorFormPage = lazy(() => import('../pages/MentorFormPage'));

/*
  Moving to React Router V6, Routes does not allow extend Route. Therefore, using Outlet component can be used in a parent <Route> element to render out child elements
  https://medium.com/@dennisivy/creating-protected-routes-with-react-router-v6-2c4bbaf7bc1c
*/
const PrivateRoutes = (): JSX.Element => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: '/login' }} state={{ from: window.location.pathname }} />
  );
};
