import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Spin } from 'antd';

export const LoadingCircle: React.FC = () => {
  return (
    <Layout
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
      }}
    >
      <Spin indicator={<LoadingOutlined title='loading' style={{ fontSize: 24, color: '#7310DB' }} spin />} />
    </Layout>
  );
};
